import {useContext} from "react";
import styles from "../styles/reader.module.css";

const ErrorMessage = (props) => {

    return (
        <h1 className={styles.error} dangerouslySetInnerHTML={{__html: props.message}}></h1>
    );


};

export default ErrorMessage;
