import {useContext, useState} from "react";
import styles from "../styles/reader.module.css";
import {Context} from "../context";

const SuccessMessage = (props) => {

    const {addPeople, error} = useContext(Context);

    return (
        <div>
            {
                props.max === props.scanned ? (
                    <h1 className={styles.error} dangerouslySetInnerHTML={{__html: props.message}}></h1>
                ) : (
                    <div>
                        <h1 className={styles.success} dangerouslySetInnerHTML={{__html: props.message}}></h1>
                        {
                            props.scanned < props.quantity ? (
                                <div className={styles.additionalButtonWrapper}>
                                    <button className={styles.btnAddPeople} onClick={() => {
                                    addPeople(props.ticketId).then(r => console.log(r))
                                    }}>Noch einer</button>
                                    <button className={styles.btnAddPeople} onClick={() => {
                                    addPeople(props.ticketId,1).then(r => console.log(r))
                                    }}>Alle</button>
                                </div>) : ('')
                        }

                    </div>
                )
            }
            <div>{error}</div>
        </div>
    );


};

export default SuccessMessage;
