import {useContext} from "react";
import {Context} from "../context";

import styles from "../styles/reader.module.css";
import SuccessMessage from "./SuccesMessage";
import ErrorMessage from "./ErrorMessage";

const Output = () => {
    const {output} = useContext(Context);
    const message = output.message;
    const success = output.success;
    const quantity = output.quantity;
    const scanned = output.scanned;
    const max = output.max;
    const ticketId = output.ticketId;

    return (
        <div className={styles.output}>{success ?
            <SuccessMessage ticketId={ticketId} message={message} scanned={scanned} quantity={quantity} max={max} /> :
            <ErrorMessage message={message} />}
		</div>
    );


};

export default Output;
