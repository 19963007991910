import { useContext } from "react";
import Output from "./components/Output";

import Reader from "./components/Reader";
import { Context } from "./context";

import styles from "./styles/app.module.css";

function App() {
	const { showReader, setShowReader, setError, setResult, output, setOutput } = useContext(Context);

	return (
		<div className={styles.container}>
			{
				showReader ? (
					<Reader />
				) : output ? (
					<div>
						<Output />
						<div className={styles.center}>
							<button onClick={() => {
								setResult(null);
								setError(null);
								setOutput(null);
								setShowReader(true);
							}} className={styles.btnScan}>SCAN!
							</button>
						</div>
					</div>
				) : (
					<div>
						<button onClick={() => setShowReader(true)} className={styles.btnScan}>SCAN!</button>
						<div className={styles.version}>Version 1.1</div>
					</div>
				)
			}
		</div>
	);
}

export default App;
