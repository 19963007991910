import { createContext, useState } from "react";

const endpoint = process.env.NODE_ENV === "development" ? "https://test.race-at-airport.de/API/tickets/scan" : "https://test.race-at-airport.de/API/tickets/scan";

const CONFIG = {
	delay: 300,
	constraints: {
		facingMode: "environment",
	},
};

export const Context = createContext();

export const ContextProvider = ({ children }) => {

	const [loading, setLoading] = useState(null);
	const [showReader, setShowReader] = useState(false);
	const [result, setResult] = useState();
	const [output, setOutput] = useState(null);
	const [error, setError] = useState(null);

	const fetchDataAsync = async (tempResult) => {

		const parameter = tempResult ?? result;

		try {
			setLoading(!loading);
			const request = await fetch(endpoint + '/?qrCode=' + parameter, {
				method: "GET",
				headers: {
					"Content-Type": "application/json",
				}
			});
			const response = await request.json();
			setOutput(response);
			setLoading(false);
		} catch (err) {
			setError(err);
		}

	};

	const addPeople = async (ticketId, all = 0) => {

		try {
			const request = await fetch(endpoint + '/?id=' + ticketId + '&all=' + all, {
				method: "GET",
				headers: {
					"Content-Type": "application/json",
				}
			});
			const response = await request.json();
			setOutput(response);
		} catch (err) {
			setError(err);
		}

	};

	return (
		<Context.Provider
			value={{
				setLoading,
				showReader,
				setShowReader,
				result,
				setResult,
				output,
				setOutput,
				error,
				setError,
				loading,
				CONFIG,
				fetchDataAsync,
				addPeople,
			}}
		>
			{children}
		</Context.Provider>
	);
};
